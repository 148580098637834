import React from "react";
import styleSelf from "./galleryitem.module.css";
import Img from "gatsby-image"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"


export default () => (
    <StaticQuery
      query={graphql`
      query {
            logo: file(relativePath: { eq: "images/frontpage-logo.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 64, height: 64) {
                ...GatsbyImageSharpFixed
                }
            }
            }
        }
      `}
      render={data => (
        <div>
            <br/><br/>
            <Link to="/"><Img fixed={data.logo.childImageSharp.fixed} /></Link>
            <br/><br/>
        </div>
      )}
    />
  )